<template>
    <div v-if="mountedAll" class="edit-style">
        <StackRouterHeaderBar
            :class="{ 'bottom-border': showHeaderTitle }"
            :title="$translate('IDEAL_TYPE')"
            :show-title="true"
            :leftButtonHandler="submit"
            :rightButtonHandler="submit"
        />
        <main class="main edit-style-main" @scroll="onScroll">
            <div class="main-cont m-l-20 m-r-20">
                <div class="premium-title f-18 f-bold m-b-13">기본 선호사항</div>
            </div>
            <div class="types">
                <div @click="editType(type.key)" class="item" :key="type.key" v-for="type in types">
                    <div class="item-box">
                        <div class="key" v-html="$translate(`STYLE_` + type.title)" />
                        <div class="value" v-html="$translate(type.value)" />
                    </div>
                    <div class="hr m-b" />
                </div>
            </div>
            <div class="premium-cont m-l-20 m-r-20 m-b-20">
                <div class="premium-title f-18 f-bold">
                    {{ isUsingManager === 'manager_active' ? '프로 매니저 회원 전용' : '멤버십 회원 전용' }}
                </div>
                <div
                    v-if="!isPremium && isUsingManager === 'manager_active' && planDetailCode !== 'pro_manager'"
                    class="premium-cta flex-row m-t-15"
                >
                    <div v-if="isUsingManager === 'manager_active'" class="upgrade-description f-13 f-medium">
                        프로 매니저로 변경하고<br />니즈에 맞는 회원분을 소개받아보세요!
                    </div>
                    <div v-else class="upgrade-description f-13 f-medium">
                        회원님의 니즈에 맞는 <br />회원분을 더 빨리 만나보세요!
                    </div>
                    <div v-if="isUsingManager === 'manager_active'" class="upgrade f-12" @click="onClickPremium">
                        <span class="upgrade-text">매니저 변경하기</span>
                    </div>
                    <div v-else class="upgrade f-12" @click="onClickPremium">
                        <span class="upgrade-text">업그레이드 하기</span>
                    </div>
                </div>
            </div>
            <div class="types">
                <div @click="editType(type.key)" class="item" :key="type.key" v-for="type in premiumTypes">
                    <div class="item-box">
                        <div class="key" v-html="$translate(`STYLE_` + type.title)" />
                        <div
                            v-if="
                                (isUsingManager !== 'manager_active' && !isPremium) ||
                                (isUsingManager === 'manager_active' && planDetailCode !== 'pro_manager')
                            "
                            class="value m-r-10"
                        >
                            <img :src="require(`@/assets/images/icons/fi-sr-lock.png`)" alt="lock" width="16px" />
                        </div>
                        <div v-else class="value" v-html="$translate(type.value)" />
                    </div>
                    <div class="hr m-b" />
                </div>
            </div>
        </main>
        <!-- <BottomButton :needAddInfoTop="false" :disabled="disabled" @click="submit" :label="$translate('SAVE')" /> -->
    </div>
</template>

<script>
// import EditIntro from './components/EditIntro'
import userService from '@/services/user'
import inquiryService from '@/services/inquiry'
import matchPreference from '@/assets/constants/match-preference'
import { getPlanNameById } from '@/store/data/product'

export default {
    name: 'EditStylePage',
    // components: { EditIntro },
    data: () => ({
        mountedAll: false,
        // types: [],
        styleChanged: false,
        showHeaderTitle: false,
    }),
    // watch: {
    //     style: {
    //         deep: false,
    //         handler: 'updateStyleChanged',
    //     },
    // },
    created() {
        this.init()
        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        // this.save()
        // if (this.styleChanged) this.$toast.success('SAVED')
        this.$unregisterBackHandler()
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        style() {
            return this.me.style || {}
        },
        multiSelectItems() {
            // if (this.isUsingManager === 'manager_active') {
            //     return ['university', 'religion', 'job_detail', 'marry_plan', 'wealth', 'school']
            // }
            return ['force', 'university', 'religion', 'job_detail', 'marry_plan', 'wealth', 'school']
        },
        needAddInfoTop() {
            return `<p class='f-12 c-grey-07'>*매니저에게만 공유되는 정보입니다.</p>
                <p class='f-12 c-grey-07'>프로필에 표시되지 않아요</p>`
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans
        },
        myPlan() {
            return this.userRatePlans.onGoing.find(o => o.ptype === 'subscription_plan')
        },
        getProductName() {
            const products = this.$store.getters.products

            if (this.myPlan) {
                return products.all.find(p => p.id === this.myPlan.product_id).name
            } else {
                return null
            }
        },
        isPremium() {
            if (
                this.getProductName &&
                (this.getProductName.includes('Marry Fit') || this.getProductName.includes('프로 매니저'))
            ) {
                return true
            } else {
                return false
            }
        },
        planDetail() {
            if (this.userRatePlans.onGoing && this.userRatePlans.onGoing.length > 0) {
                return getPlanNameById(this.myPlan.product_id)
            } else {
                return null
            }
        },
        planDetailCode() {
            if (!this.planDetail) {
                return null
            } else {
                if (this.planDetail.code.includes('pro_manager')) {
                    return 'pro_manager'
                } else if (this.planDetail.code.includes('mf_3month')) {
                    return 'mf_3month'
                } else if (this.planDetail.code.includes('mfplus_3month')) {
                    return 'mfplus_3month'
                } else {
                    return 'lovefit_eternal'
                }
            }
        },
        isUsingManager() {
            // legacy_membership => 기존 멤베십 구매자
            // manager_pending => 아직 매니저 선택 적용안된 대기자
            // manager_active => 매니저 선택 적용된자
            // console.log('isUsingManager:', this.me, this.profile, this.planDetailCode);
            if (!this.me.id) {
                return 'none'
            }
            if (['mf_3month', 'mfplus_3month'].indexOf(this.planDetailCode) !== -1) {
                return 'legacy_membership'
            } else if (this.me.id >= 19640 || this.planDetailCode === 'pro_manager') {
                return 'manager_active'
            } else if (this.me.id % 4 > 0) {
                return 'manager_pending'
            } else {
                return 'manager_active'
            }
        },
        // isMF() {
        //     if (this.userRatePlan.onGoing.)
        // },
        // disabled() {
        //     const notAnswered = ['priority', 'max_age', 'min_age', 'max_height', 'min_height'].every(
        //         i => !this.style[i]
        //     )
        //     const notAnsweredSmoking = this.style.smoking_type === null ? true : false
        //     const emptyListAnswer = this.multiSelectItems.every(i => !this.style[`${i}_type`].length)

        //     return notAnswered && notAnsweredSmoking && emptyListAnswer
        // },
        types() {
            // return ['suggestion_method', 'priority', 'marry'].map(key => ({
            return ['priority', 'marry'].map(key => ({
                key,
                title: `${key.toUpperCase()}_TYPE`,
                value: this.mountedAll ? this.typeTitle(key) : '',
            }))
        },
        premiumTypes() {
            if (this.me.gender === 0) {
                return [
                    'force',
                    'appearance',
                    'age',
                    'region',
                    'job_detail',
                    'wealth',
                    'school',
                    'religion',
                    'marry_plan',
                    'height',
                ].map(key => ({
                    key,
                    title: `${key.toUpperCase()}_TYPE`,
                    value: this.mountedAll ? this.typeTitle(key) : '',
                }))
            } else {
                return [
                    'force',
                    'appearance',
                    'age',
                    'height',
                    'wealth',
                    'job_detail',
                    'school',
                    'region',
                    'religion',
                    'marry_plan',
                ].map(key => ({
                    key,
                    title: `${key.toUpperCase()}_TYPE`,
                    value: this.mountedAll ? this.typeTitle(key) : '',
                }))
            }
        },
    },
    methods: {
        submit() {
            // this.save().then(() => this.$stackRouter.pop())
            this.$stackRouter.pop()
        },
        multiSelect(key) {
            // if (this.isUsingManager === 'manager_active') {
            //     return [
            //         'religion_type',
            //         'university_type',
            //         'job_detail_type',
            //         'marry_plan_type',
            //         'wealth_type',
            //         'school_type',
            //     ].includes(key)
            // }
            return [
                'religion_type',
                'university_type',
                'job_detail_type',
                'marry_plan_type',
                'wealth_type',
                'force_type',
                'school_type',
            ].includes(key)
        },
        typeTitle(key) {
            let value = ''
            if (this.multiSelect(`${key}_type`) && key !== 'wealth') {
                const arr = this.style[`${key}_type`] || []
                if (typeof arr === 'string') {
                    setTimeout(() => {
                        const setArr = JSON.parse(arr)
                        const newArr = []
                        setArr.forEach(i => {
                            const foundObject = matchPreference[key].find(t => t.value === i)

                            if (foundObject) {
                                newArr.push(foundObject)
                            }
                        })
                        newArr.forEach(item => {
                            value += `${item.title}, `
                        })
                    }, 1000)
                } else {
                    arr.forEach(item => {
                        value += `${item.title}, `
                    })
                }

                value = value.slice(0, value.length - 2)
            } else if (['priority', 'age', 'height', 'region'].includes(key)) {
                if (key === 'age') {
                    value = `${this.style.min_age} ~ ${this.style.max_age} 세`
                } else if (key === 'height') {
                    value = `${this.style.min_height} ~ ${this.style.max_height} cm`
                } else if (key === 'region') {
                    value = `집으로부터 ${this.style.house_distance}km, 직장으로부터 ${this.style.job_distance}km`
                } else if (key === 'priority') {
                    if (this.style.priority === 6) {
                        value = '조건이 훨씬 더 중요'
                    } else if (this.style.priority === 5) {
                        value = '조건이 더 중요'
                    } else if (this.style.priority === 4) {
                        value = '조건이 약간 더 중요'
                    } else if (this.style.priority === 3) {
                        value = '외적인 요소가 약간 더 중요'
                    } else if (this.style.priority === 2) {
                        value = '외적인 요소가 더 중요'
                    } else if (this.style.priority === 1) {
                        value = '외적인 요소가 훨씬 더 중요'
                    }
                }
            } else if (key === 'wealth') {
                this.style.wealth_type.length > 0 ? (value = '선택 완료') : (value = '')
            } else if (key === 'appearance') {
                if (
                    this.style.appearance_type === '' ||
                    !this.style.appearance_type ||
                    this.style.appearance_type === 'null' ||
                    null
                ) {
                    value = '입력해주세요'
                } else {
                    value = this.style.appearance_type
                }
            } else {
                value = (matchPreference[key].find(t => t.value === this.style[`${key}_type`]) || {}).title
            }

            if (value) {
                return `<span style='max-width: 240px;' class="c-primary f-medium lines-1">${value}</span><i class='material-icons m-l-16 f-16'>chevron_right</i>`
            } else {
                if (key === 'priority' || key === 'force') {
                    return `<span style='max-width: 240px;' class="c-primary f-medium lines-1">설정해주세요</span><i class='material-icons m-l-16 f-16'>chevron_right</i>`
                } else if (['wealth', 'religion', 'job_detail', 'marry_plan', 'school'].includes(key)) {
                    return `<span style='max-width: 240px;' class="c-primary f-medium lines-1">전부 괜찮아요</span><i class='material-icons m-l-16 f-16'>chevron_right</i>`
                } else {
                    return `<i class='material-icons f-16'>chevron_right</i>`
                }
            }
        },
        async editType(key) {
            // console.log("editType", key);
            if (
                this.premiumTypes.map(item => item.key).includes(key) &&
                ((this.isUsingManager !== 'manager_active' && !this.isPremium) ||
                    (this.isUsingManager === 'manager_active' && this.planDetailCode !== 'pro_manager'))
            ) {
                this.onClickPremium(true)
            } else {
                if (key === 'priority') {
                    this.$modal
                        .custom({
                            component: 'ModalStylePriority',
                            options: {
                                key,
                                // style: this.style,
                                style: { priority: this.style.priority },
                            },
                        })
                        .then(response => {
                            // console.log(response, this.style.priority)
                            if (!response) return
                            if (response !== this.style.priority) {
                                this.updateStyleChanged()
                            }

                            this.style.priority = response
                            // this.initTypes()
                            this.saveEachStyle()
                        })
                } else if (['age', 'height', 'region'].includes(key)) {
                    this.$modal
                        .custom({
                            component: 'ModalEditStyleSlider',
                            options: {
                                key,
                                style: this.style,
                            },
                        })
                        .then(response => {
                            if (!response) return

                            if (key === 'age') {
                                // console.log(response, [this.style.min_age, this.style.max_age])
                                if (
                                    response.slice(0, 2).join(',') !==
                                    [this.style.min_age, this.style.max_age].join(',')
                                ) {
                                    this.updateStyleChanged()
                                }
                                this.style.min_age = response[0]
                                this.style.max_age = response[1]
                            } else if (key === 'height') {
                                // console.log(response, [this.style.min_height, this.style.max_height])
                                try {
                                    if (
                                        response.slice(0, 2).join(',') !==
                                        [this.style.min_height, this.style.max_height].join(',')
                                    ) {
                                        this.updateStyleChanged()
                                    }
                                    this.style.min_height = response[0]
                                    this.style.max_height = response[1]
                                } catch (e) {
                                    console.log(e)
                                }
                            } else if (key === 'region') {
                                const { house, job } = response
                                // console.log('house:', house, 'job:', job)
                                if (house !== this.style.house_distance || job !== this.style.job_distance) {
                                    this.updateStyleChanged()
                                }
                                this.style.house_distance = house
                                this.style.job_distance = job
                            }
                            // this.initTypes()
                            this.saveEachStyle()
                        })
                } else if (key === 'appearance') {
                    await this.$modal
                        .custom({
                            component: 'ModalEditAppearanceType',
                            options: {
                                text: this.style.appearance_type,
                                key,
                            },
                        })
                        .then(response => {
                            if (!response) return
                            // console.log(response, this.style.appearance_type, this.styleChanged)
                            const { items } = response
                            if (items !== this.style.appearance_type) {
                                this.updateStyleChanged()
                            }
                            this.style.appearance_type = items
                            // this.initTypes()
                            this.saveEachStyle()
                        })
                } else {
                    this.$modal
                        .custom({
                            component: 'ModalEditStyleType',
                            options: {
                                key,
                                isUsingManager: this.isUsingManager,
                            },
                        })
                        .then(response => {
                            if (!response) return
                            const { items } = response
                            // console.log(key, response, this.style[`${key}_type`], this.styleChanged)
                            // console.log('response', response, this.style[`${key}_type`]);
                            // if (response !== this.style[`${key}_type`]) {
                            //     this.updateStyleChanged()
                            // }
                            if (this.multiSelect(`${key}_type`)) {
                                const newVal = items.filter(item => item.$$selected)
                                if (
                                    newVal
                                        .map(item => item.value)
                                        .sort()
                                        .join(',') !==
                                    this.style[`${key}_type`]
                                        .map(item => item.value)
                                        .sort()
                                        .join(',')
                                ) {
                                    this.updateStyleChanged()
                                }
                                this.style[`${key}_type`] = newVal
                            } else {
                                const newVal = items.find(item => item.$$selected).value
                                if (newVal !== this.style[`${key}_type`]) {
                                    this.updateStyleChanged()
                                }
                                this.style[`${key}_type`] = newVal
                            }
                            // this.initTypes()
                            this.saveEachStyle()
                        })
                }
            }
        },
        saveEachStyle() {
            // console.log('saveEachStyle',this.styleChanged)
            if (this.styleChanged) {
                this.save().then(() => {
                    this.$toast.success('SAVED')
                    this.styleChanged = false
                    // console.log('saveEachStyle2',this.styleChanged)
                })
            }
        },
        async init() {
            await this.$store.dispatch('loadMe')
            this.ageValue = [
                // this.style.min_age || this.ageSliderOptions.min,
                // this.style.max_age || this.ageSliderOptions.max,
                this.style.min_age,
                this.style.max_age,
            ]
            this.heightValue = [this.style.min_height, this.style.max_height]

            // this.ageRange = [this.ageSliderOptions.min, this.ageSliderOptions.max]
            // this.heightRange = [this.heightSliderOptions.min, this.heightSliderOptions.max]
            // 다중선택 가능한 옵션
            this.multiSelectItems.forEach(key => {
                const typeArr =
                    key === 'religion' && this.style[`${key}_ids`]
                        ? JSON.parse(this.style[`${key}_ids`])
                        : key === 'university' && this.style[`${key}_grades`]
                        ? JSON.parse(this.style[`${key}_grades`])
                        : this.style[`${key}_type`]
                        ? JSON.parse(this.style[`${key}_type`])
                        : []
                // const typeArr = this.style[`${key}_type`] ? JSON.parse(this.style[`${key}_type`]) : []
                const styleType = (matchPreference[key] || []).filter(
                    item => typeArr.includes(item.value) && item.gender.includes(this.me.gender)
                )
                this.$set(this.style, `${key}_type`, styleType)
            })
            this.mountedAll = true
        },
        preparedPayload() {
            const form = new FormData()
            this.multiSelectItems.forEach(item => {
                const arr = []
                this.style[`${item}_type`].forEach(i => arr.push(i.value))

                const lastStr = item === 'religion' ? '_ids' : item === 'university' ? '_grades' : '_type'
                form.append(`${item}${lastStr}`, arr.length ? JSON.stringify(arr) : '')
            })

            // if (this.isUsingManager === 'manager_active') {
            //     form.append('force_type', JSON.stringify([this.style.force_type]))
            // }
            // form.append(
            //     'smoking_type',
            //     this.style.smoking_type ? this.style.smoking_type : this.style.smoking_type === 0 ? 0 : ''
            // )
            form.append('priority', this.style.priority)
            form.append('suggestion_method_type', this.style.suggestion_method_type)
            form.append('max_age', this.style.max_age)
            form.append('min_age', this.style.min_age)
            form.append('intro', this.style.intro || '')
            form.append('max_height', this.style.max_height)
            form.append('min_height', this.style.min_height)
            form.append('house_distance', this.style.house_distance)
            form.append('job_distance', this.style.job_distance)
            form.append('appearance_type', this.style.appearance_type)
            form.append('marry_type', this.style.marry_type)
            // console.log('preparedPayload', form);
            return form
        },
        async onClickPremium(useModal = false) {
            if (useModal === true && this.isUsingManager === 'manager_active') {
                this.$modal.basic({
                    // title: '약속이 확정되면 입력할 수 있습니다.',
                    body:
                        '디테일한 선호 사항은 프로 매니저 담당 회원만 선택 가능합니다. 매니저를 변경하고 니즈에 맞는 분을 소개 받아보세요😊',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: '매니저 변경하기',
                            class: 'btn-primary',
                            callback: () => {
                                // console.log('####');
                                this.$stackRouter.pop()
                                this.$router.push({
                                    // name: item.route,
                                    name: 'ManagerIntroductionPage',
                                    // params: { selectedMembership: this.planDetail.code },
                                    params: { selectedMembership: 'pro_manager' },
                                })
                            },
                        },
                    ],
                })
                return
            } else if (this.isUsingManager === 'manager_active') {
                this.$stackRouter.pop()

                const payload = {
                    user_id: this.$store.getters.me.id,
                    category: 'manager',
                    funnel: 'enter_manager_list',
                }
                await inquiryService.updateFunnel(payload)

                this.$router.push({
                    // name: item.route,
                    name: 'ManagerIntroductionPage',
                    // params: { selectedMembership: this.planDetail.code },
                    params: { selectedMembership: 'pro_manager' },
                })
                return
            }

            this.$stackRouter.pop()

            // if (this.isUsingManager === 'manager_active') {
            //     this.$router.push({
            //         // name: item.route,
            //         name: 'ManagerIntroductionPage',
            //         params: { selectedMembership: this.planDetail.code },
            //     })
            //     return
            // }
            const payload = {
                user_id: this.$store.getters.me.id,
                category: 'membership',
                funnel: 'enter_membership_list',
            }
            await inquiryService.updateFunnel(payload)
            this.$router.push({
                name: 'MembershipIntroductionPage',
                // params: { categoryName: this.category.category },
            })
        },
        // async save() {
        //     try {
        //         // this.saveSliderData()
        //         await userService.createStyles(this.preparedPayload())
        //         this.$store.dispatch('loadMe')
        //         this.$store.dispatch('loadSettings')
        //     } catch (e) {
        //         // this.$toast.error('선호사항을 모두 입력해주세요')
        //     }
        // },
        async save() {
            try {
                // this.saveSliderData()
                const response = await userService.createStyles(this.preparedPayload())
                // if (response) {
                //     this.$store.dispatch('loadMe')
                //     this.$store.dispatch('loadSettings')
                // }
                return response
            } catch (e) {
                this.$toast.error('savve e', e)
            }
        },
        // saveSliderData() {
        //     if (this.$refs.ageSlider.updated) {
        //         this.style.min_age = this.$refs.ageSlider.newRange[0]
        //         this.style.max_age = this.$refs.ageSlider.newRange[1]
        //     }
        //     if (this.$refs.heightSlider.updated) {
        //         this.style.min_height = this.$refs.heightSlider.newRange[0]
        //         this.style.max_height = this.$refs.heightSlider.newRange[1]
        //     }
        // },
        updateStyleChanged() {
            this.styleChanged = true
        },
        onScroll(event) {
            this.showHeaderTitle = event.target.scrollTop >= 52
        },
        back() {
            // console.log("1111")
            this.$stackRouter.pop()
        },
    },
}
</script>

<style lang="scss" scoped>
.edit-style {
    $header-height: 52px;
    overflow: hidden;

    .bottom-border {
        border-bottom: 1px solid $grey-02;
    }

    .main {
        height: calc(100vh - #{$header-height} - 48px);
        padding-top: 20px;
        padding-bottom: 20px;
        overflow-y: auto;
    }

    .types {
        .item {
            .item-box {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 15px;
            }
        }

        .key {
            @include spoqa-f-medium;
            font-size: 16px;
            color: black;
            width: fit-content;
        }

        .value {
            @include spoqa-f-medium;
            @include center;
            color: $grey-05;
            font-size: 14px;

            i {
                margin-left: 8px;
            }
        }
    }

    .premium-cont {
        margin-top: 55px;
    }

    .premium-title {
        color: #151360;
    }

    .premium-cta {
        background: #151360;
        border-radius: 8px;
        padding: 10px 12px;
        display: flex;
        justify-content: space-between;
        // @include center;

        .upgrade {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            // @include center;
            margin: 5px 0px;
            background: white;
            padding: 9px 10px;
            max-width: 96px;
            min-width: 96px;
            max-height: 36px;
            border-radius: 24px;
            .upgrade-text {
                font-size: clamp(10px, 2vw, 12px);
                white-space: nowrap;
                flex-shrink: 1;
            }
        }
        .upgrade-description {
            max-width: 200px;
            color: white;
            margin-right: 10px;
            line-height: 150%;
        }
    }

    .height-warning {
        color: $grey-05;
        letter-spacing: -0.2px;
        line-height: normal;
        font-size: 12px;
        text-align: center;
        @include f-regular;
    }

    hr {
        margin: 32px 0;
        background: $grey-02;
        height: 1px;
        border-bottom: none;
    }

    .required::after {
        content: ' *';
        color: #ff3d6b;
    }
}
</style>
